<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addPromotionContentBlocksModal' hide-footer>
        <addPromotionContentBlocks @closeAddPromotionContentBlocks='toggleAddPromotionContentBlocks()' @addPromotionContentBlocksSuccess='addPromotionContentBlocksSuccess()'></addPromotionContentBlocks>
      </b-modal>
      <b-modal ref='editPromotionContentBlocksModal' hide-footer>
        <editPromotionContentBlocks
          :editingPromotionContentBlocks='currentPromotionContentBlocks'
          @closeEditPromotionContentBlocks='toggleEditPromotionContentBlocks()'
          @editPromotionContentBlocksSuccess='editPromotionContentBlocksSuccess()'
        ></editPromotionContentBlocks>
      </b-modal>
      <div class='row'>
        <h2>PromotionContentBlocks</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddPromotionContentBlocks()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addPromotionContentBlocks from '../../components/addPromotionContentBlocks';
import editPromotionContentBlocks from '../../components/editPromotionContentBlocks';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'PromotionId',
                field: 'PromotionId',
                sort: 'asc',
              },
              {
                label: 'Heading',
                field: 'Heading',
                sort: 'asc',
              },
              {
                label: 'Copy',
                field: 'Copy',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'FullWidth',
                field: 'FullWidth',
                sort: 'asc',
              },
              {
                label: 'Location',
                field: 'Location',
                sort: 'asc',
              },
              {
                label: 'DisplayOrder',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'Link',
                field: 'Link',
                sort: 'asc',
              },
              {
                label: 'LinkFriendly',
                field: 'LinkFriendly',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addPromotionContentBlocksOpen: false,
      editPromotionContentBlocksOpen: false,
      currentPromotionContentBlocks: {}
    };
  },
  components: {
    addPromotionContentBlocks,
    editPromotionContentBlocks,
    Datatable,
  },
  created: function() {
    this.getPromotionContentBlocks();
    this.addPromotionContentBlocksOpen = false;
    this.editPromotionContentBlocksOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getPromotionContentBlocks() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getPromotionContentBlocks', payload);
    },
    editItem(PromotionContentBlocks) {
      this.toggleEditPromotionContentBlocks();
      this.currentPromotionContentBlocks = PromotionContentBlocks;
    },
    toggleAddPromotionContentBlocks() {
      if(this.addPromotionContentBlocksOpen)
      {
        this.$refs.addPromotionContentBlocksModal.hide()
      }
      else{
        this.$refs.addPromotionContentBlocksModal.show()
      }
      this.addPromotionContentBlocksOpen = !this.addPromotionContentBlocksOpen;
    },
    addPromotionContentBlocksSuccess() {
      this.toggleAddPromotionContentBlocks();
      miniToastr['success']('PromotionContentBlocks Added', 'Success', 1000, null);
      this.getPromotionContentBlocks();
    },
    toggleEditPromotionContentBlocks() {
      if(this.editPromotionContentBlocksOpen)
      {
        this.$refs.editPromotionContentBlocksModal.hide()
      }
      else{
        this.$refs.editPromotionContentBlocksModal.show()
      }
      this.editPromotionContentBlocksOpen = !this.editPromotionContentBlocksOpen;
    },
    editPromotionContentBlocksSuccess() {
      this.toggleEditPromotionContentBlocks();
      miniToastr['success']('PromotionContentBlocks Edited', 'Success', 1000, null);
      this.getPromotionContentBlocks();
    }
  }
};
</script>
<style scoped>
.PromotionContentBlocksThumbnail {
  height: 50px;
}
</style>
